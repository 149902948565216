import { type UserSession } from '@/stores/loginStore';

const USER_SESSION_STORAGE_KEY = 'custom-persisted-user-session';
const useUserSession = () => {

    const getFromLocalStorage = (): UserSession => {
        const item = localStorage.getItem(USER_SESSION_STORAGE_KEY);
        if (item === null) {
            return { // default value
                isAuthenticated: false,
                accessToken: null,
                refreshToken: null,
                userId: null,
                accessTokenExpiration: null,
                refreshTokenTimeout: undefined,
                language: 'cs',
                sessionId: null,
            };
        }
        return JSON.parse(item);
    };

    const saveToLocalStorage = (value: UserSession) => {
        localStorage.setItem(USER_SESSION_STORAGE_KEY, JSON.stringify(value));
    };

    const savePartialToLocalStorage = (value: Partial<UserSession>) => {
        localStorage.setItem(
            USER_SESSION_STORAGE_KEY,
            JSON.stringify({
                ...getFromLocalStorage(),
                ...value,
            })
        );
    };

    return {
        userSession: getFromLocalStorage,
        saveUserSession: saveToLocalStorage,
        savePartialUserSession: savePartialToLocalStorage,
        getAccessToken: () => getFromLocalStorage().accessToken,
        getLanguage: () => getFromLocalStorage().language,
        getAuthorizationHeader: (): any => {
            const token = getFromLocalStorage().accessToken;
            if (token !== null) {
                return {
                    Authorization: `Bearer ${token}`,
                };
            } else {
                return {};
            }
        },
        getSessionId: (): number | null => getFromLocalStorage().sessionId,
        getAccessTokenExpiration: (): string | null =>
            getFromLocalStorage().accessTokenExpiration,
        isAccessTokenExpired: (): boolean => {
            const expiration = getFromLocalStorage().accessTokenExpiration;
            if (expiration === null) {
                return true;
            }
            const date: Date = new Date(expiration);
            // expired minute before expiration
            return date.getTime() < Date.now() + 60 * 1000;
        },
        getRefreshToken: (): string | null =>
            getFromLocalStorage().refreshToken,
        getRefreshTokenTimeout: (

        ): ReturnType<typeof setTimeout> | undefined =>
            getFromLocalStorage().refreshTokenTimeout,
        getIsAuthenticated: (): boolean =>
            getFromLocalStorage().isAuthenticated,
    };
}

export default useUserSession;
